import { Routes } from '@angular/router';


export const publicRoutes: Routes = [
    { path: 'activation', loadChildren: () => import('./activation/activation.module').then(m => m.ActivationModule) },
    { path: 'activation-with-google', loadChildren: () => import('./activation-with-google/activation-with-google.module').then(m => m.ActivationWithGoogleModule) },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
	{ path: 'password-reset', loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetModule) }
]
