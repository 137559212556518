import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { publicRoutes } from './public-area/public-area-routing.module';

@NgModule({
	imports: [
		RouterModule.forRoot([
			...publicRoutes,
			{ path: '', pathMatch: 'full', redirectTo: 'view' }
		], { canceledNavigationResolution: 'computed' }) // needed to compute correctly history.back
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }