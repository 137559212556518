import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BlockedFeatureTooltipDirective } from '../shared/directives/blocked-feature-tooltip.directive';
import { FormDeactivateGuard } from '../shared/guards/form-deactivate.guard';
import { SecondLevelFormDeactivateGuard } from '../shared/guards/second-level-form-deactivate.guard';
import { ThirdLevelFormDeactivateGuard } from '../shared/guards/third-level-form-deactivate.guard';
import { CanDeactivateUnsavedCheck } from '../shared/guards/unsaved-check.guard';
import { PrivateAreaRoutingModule } from './private-area-routing.module';
import { PrivateAreaGuard } from './private-area.guard';
import { ViewComponent } from './view/view.component';

@NgModule({
    imports: [
        BlockedFeatureTooltipDirective,
        CommonModule,
        FormsModule,
        MaterialModule,
        PrivateAreaRoutingModule
    ],
    declarations: [ViewComponent],
    providers: [
        PrivateAreaGuard,
        CanDeactivateUnsavedCheck,
        FormDeactivateGuard,
        SecondLevelFormDeactivateGuard,
        ThirdLevelFormDeactivateGuard
    ]
})
export class PrivateAreaModule { }