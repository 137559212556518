<form-dialog [dialogTitle]="title" [saveDisabled]="!form?.valid" (saveAction)="save()">
	<form #form="ngForm">
		<form-column>
			<div class="material-field-wrapper">
				<span class="material-field-title">Category:</span>
				<mat-radio-group [(ngModel)]="category" [ngModelOptions]="{standalone: true}" class="w-100 inline-radio-buttons">
					<mat-radio-button value="ORGANIZATION">Organization</mat-radio-button>
					@if (showPartner) {
						<mat-radio-button value="PARTNER">Partner</mat-radio-button>
					}
					<mat-radio-button value="CUSTOMER">Customer</mat-radio-button>
				</mat-radio-group>
			</div>
			@if (category == 'ORGANIZATION') {
				<mat-form-field appearance="fill">
					<mat-label>Organization</mat-label>
					<mat-select placeholder="Organization" [ngModel]="userView?.organizationId" name="organizationId" required>
						@for (organization of organizations; track organization) {
							<mat-option [value]="organization.id">
								{{ organization.name }}
							</mat-option>
						}
					</mat-select>
				</mat-form-field>
			}
			@if (category == 'PARTNER') {
				<partner-autocomplete-input label="Partner" formClass="w-100" [ngModel]="userView?.partnerId" name="partnerId" required />
			}
			@if (category == 'CUSTOMER') {
				<customer-autocomplete-input label="Customer" formClass="w-100" [ngModel]="userView?.customerId" name="customerId" (ngModelChange)="updateLocations($event)" required />
			}

			<mat-form-field appearance="fill">
				<mat-label>User Type</mat-label>
				<mat-select placeholder="User Type" [ngModel]="userView?.userTypeId" name="userTypeId" required>
					@for (userType of userTypes[category]; track userType) {
						<mat-option [value]="userType.id">
							{{ userType.name }}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>
			@if (category == 'CUSTOMER') {
				<div class="material-field-wrapper">
					<span class="material-field-title">Access to:</span>
					<mat-radio-group [(ngModel)]="accessAllLocations" [ngModelOptions]="{standalone: true}" class="w-100 inline-radio-buttons">
						<mat-radio-button [value]="true">All locations</mat-radio-button>
						<mat-radio-button [value]="false">Single location</mat-radio-button>
					</mat-radio-group>
				</div>
			}
			@if (!accessAllLocations) {
				<mat-form-field appearance="fill">
					<mat-label>Location</mat-label>
					<mat-select placeholder="Location" [ngModel]="userView?.locationId" name="locationId">
						<mat-option [value]="null"></mat-option>
						@for (location of locations; track location) {
							<mat-option [value]="location.id">
								{{ location.name }}
							</mat-option>
						}
					</mat-select>
					<mat-hint>Limit the access to a specific location</mat-hint>
				</mat-form-field>
			}
		</form-column>
	</form>
</form-dialog>
