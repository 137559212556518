import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService, ContextService, NavigationService, TokenStorageService, ViewService } from '../services';

@Injectable()
export class PrivateAreaGuard  {

    constructor(
        private tokenService: TokenStorageService,
        private navigationService: NavigationService,
        private contextService: ContextService,
        private authenticationService: AuthenticationService,
        private viewService: ViewService
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        let error = route.queryParamMap.get('error');
        if (error) {
            this.authenticationService.logout({ queryParams: { error: error } });
            return false;
        } else {
            let token = route.queryParamMap.get('token');
            if (token) {
                this.tokenService.saveToken(token);
                this.navigationService.goToMainPage({ queryParams: { 'token': null }, queryParamsHandling: 'merge' });
                return this.initCurrentUser();
            } else {
                let storedToken = this.tokenService.getToken();
                if (storedToken) {
                    return this.initCurrentUser();
                } else {
                    this.authenticationService.logout();
                    return false;
                }
            }
        }
    }

    private initCurrentUser(): boolean | Promise<boolean> {
        this.contextService.setGeneralContext();
        return this.viewService.initTextConfig().catch(() => { }).then(() => {
            if (this.contextService.getUser()) {
                return true;
            }
            return this.contextService.initCurrentUser().then(() => true).catch(() => false);
        });
    }
}