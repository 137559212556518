import { CommonModule } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator, Validators } from "@angular/forms";
import { mergeMap, Observable } from "rxjs";
import { MaterialModule } from "../../../material.module";
import { Partner } from "../../../models";
import { PartnerService } from "../../../services";

export const PARTNER_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PartnerAutocompleteInputComponent),
    multi: true
};

@Component({
    standalone: true,
    selector: 'partner-autocomplete-input',
    imports: [CommonModule, MaterialModule, ReactiveFormsModule],
    templateUrl: './partner-autocomplete-input.component.html',
    providers: [PARTNER_AUTOCOMPLETE_CONTROL_VALUE_ACCESSOR]
})
export class PartnerAutocompleteInputComponent implements ControlValueAccessor, Validator, OnInit {

    @Input() label: string;

    @Input() formClass: string;

    @Input() required: boolean | string;

    onChange: any = () => { };
    onTouch: any = () => { };
    onValidatorChange: any = () => { };
    filteredPartners: Observable<Partner[]>;
    control: FormControl = new FormControl('');
    partnersByName: { [partnerName: string]: Partner } = {};
    val: string;

    constructor(private partnerService: PartnerService) { }

    ngOnInit(): void {
        this.filteredPartners = this.control.valueChanges.pipe(
            mergeMap(nameInput => (nameInput ? this.partnerService.getPagedList(0, 50, [{ active: 'name', direction: 'asc' }], this.getExtraParams(nameInput)) : Promise.resolve({ content: [] }))
                .then(page => {
                    this.partnersByName = {};
                    page.content.forEach(c => this.partnersByName[c.name] = c);
                    this.val = this.partnersByName[nameInput]?.id;
                    this.onChange(this.val);
                    return page.content;
                }))
        );
    }

    private getExtraParams(nameInput: string): HttpParams {
        return new HttpParams().set('searchText', '*' + nameInput + '*');
    }

    set value(val: string) {
        this.val = val;
        this.fillWithPartnerName(val);
        this.onChange(val)
        this.onTouch(val)
    }

    writeValue(val: string): void {
        this.val = val;
        this.fillWithPartnerName(val);
    }

    private fillWithPartnerName(partnerId: string): void {
        if (partnerId) {
            this.partnerService.getById(partnerId).then(partner => {
                this.partnersByName = { [partner.name]: partner };
                this.control.setValue(partner.name)
            });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        return (control.hasValidator(Validators.required) && !this.val) ? { invalid: true } : null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}