import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrivateAreaGuard } from './private-area.guard';
import { ViewComponent } from './view/view.component';

@NgModule({
    imports: [
        RouterModule.forChild([{
            path: 'view',
            component: ViewComponent,
            canActivate: [PrivateAreaGuard],
            children: [
                { path: '', pathMatch: 'full', redirectTo: 'home' },
                { path: 'companies', loadChildren: () => import('./company-area/company-area.module').then(m => m.CompanyAreaModule) },
                { path: 'environments', loadChildren: () => import('./environment-area/environment-area.module').then(m => m.EnvironmentAreaModule) },
                { path: 'expert-partners', loadChildren: () => import('./expert-partner-area/expert-partner-area.module').then(m => m.ExpertPartnerAreaModule) },
                { path: 'home', loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule) },
				{ path: 'profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) }
            ]
        }
        ])
    ],
    exports: [RouterModule]
})
export class PrivateAreaRoutingModule { }
