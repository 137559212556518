import { HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MaterialModule } from "../../material.module";
import { Location, Organization, UserType, UserTypeCategory, UserView } from "../../models";
import { ContextService, LocationService, NotificationService, OrganizationService, UserTypeService, UserViewService } from "../../services";
import { CustomerAutocompleteInputComponent } from "../../shared/components/customer-autocomplete-input/customer-autocomplete-input.component";
import { FormDialogComponent } from "../../shared/components/dialogs/form-dialog.component";
import { FormColumnComponent } from "../../shared/components/form-elements/form-column.component";
import { PartnerAutocompleteInputComponent } from "../../shared/components/partner-autocomplete-input/partner-autocomplete-input.component";

@Component({
    standalone: true,
    selector: 'user-view-form-dialog',
    imports: [CustomerAutocompleteInputComponent, FormColumnComponent, FormDialogComponent, FormsModule, MaterialModule, PartnerAutocompleteInputComponent],
    templateUrl: './user-view-form-dialog.component.html'
})
export class UserViewFormDialogComponent implements OnInit {

    @ViewChild('form') form: NgForm;

    userView: UserView;
    title: string;
    category: UserTypeCategory = UserTypeCategory.ORGANIZATION;
    accessAllLocations: boolean = true;
    userTypes: { [category: string]: UserType[] } = {};
    organizations: Organization[];
    locations: Location[] = [];
    showPartner: boolean;

    constructor(
        private userTypeService: UserTypeService,
        private organizationService: OrganizationService,
        private locationService: LocationService,
        private userViewService: UserViewService,
        private notificationService: NotificationService,
        private contextService: ContextService,
        private dialogRef: MatDialogRef<UserViewFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.userView = data?.userView;
        this.title = this.userView ? 'Edit User View' : 'Add User View';
        if (this.userView?.partnerId) {
            this.category = UserTypeCategory.PARTNER;
        } else if (this.userView?.customerId) {
            this.category = UserTypeCategory.CUSTOMER;
        }
    }

    ngOnInit(): void {
        this.initUserTypes();
        this.initOrganizations();
        this.showPartner = this.contextService.hasFeature('partners');
    }

    private initUserTypes(): void {
        this.userTypeService.getRecursivelyAll().then(userTypes => {
            this.userTypes = {
                'ORGANIZATION': userTypes.filter(ut => ut.category == UserTypeCategory.ORGANIZATION),
                'PARTNER': userTypes.filter(ut => ut.category == UserTypeCategory.PARTNER),
                'CUSTOMER': userTypes.filter(ut => ut.category == UserTypeCategory.CUSTOMER)
            }
        });
    }

    private initOrganizations(): void {
        this.organizationService.getRecursivelyAll().then(organizations => this.organizations = organizations);
    }

    updateLocations(customerId: string): void {
        if (customerId) {
            let extraParams = new HttpParams().set("customerId", customerId);
            this.locationService.getRecursivelyAll(extraParams).then(locations => {
                this.locations = locations;
            }).catch(() => this.locations = []);
        } else {
            this.locations = [];
        }
    }

    save(): void {
        this.userViewService.save(this.getBody(), this.userView?.id).then(userView => {
            this.notificationService.showInfoMessage('User View created');
            this.dialogRef.close(userView);
        }).catch(err => this.notificationService.showErrorMessage(err));
    }

    private getBody(): any {
        let body = this.form.form.getRawValue(); // todo clear
        body.userId = this.contextService.getUser().id;
        body.environmentId = this.contextService.getEnvironment().id
        return body;
    }
}
