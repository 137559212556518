<mat-form-field appearance="fill" floatLabel="always" [ngClass]="formClass">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <input matInput placeholder="Type to search" [formControl]="control" [matAutocomplete]="auto" [required]="required">
  <mat-autocomplete #auto="matAutocomplete">
    @for (partner of filteredPartners | async; track partner) {
      <mat-option [value]="partner.name">
        {{partner.name}}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>