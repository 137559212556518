import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { PrivateAreaModule } from './private-area/private-area.module';
import { authInterceptorProviders } from './services';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		PrivateAreaModule,
		HttpClientModule,
		MaterialModule,
	],
	providers: [authInterceptorProviders],
	bootstrap: [AppComponent]
})
export class AppModule { }